import {
  AgentLeadResponse,
  CreateAgentLeadRequest,
  OfficeLinkResponse,
  OfficesResponse,
  RunwayControllerApi,
  StatesResponse,
} from '../openapi/bff';
import { getBFFConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from './ErrorService';

export default class BFFApiService {
  async fetchStatesMLSesAndBoards(state: string): Promise<StatesResponse> {
    const api = new RunwayControllerApi(getBFFConfiguration());
    try {
      const { data } = await api.getStateInformation(state);

      return data;
    } catch (e) {
      ErrorService.notify('Unable to fetch MLSes and Boards', e, {
        state: { stateCode: state },
      });
    }
  }
  async getOffice(state: string): Promise<OfficeLinkResponse> {
    const api = new RunwayControllerApi(getBFFConfiguration());
    try {
      const { data } = await api.getOfficialOfficeForStateOrProvince(state);

      return data;
    } catch (e) {
      ErrorService.notify('Unable to fetch official office for state', e, {
        state: { stateCode: state },
      });
    }
  }
  async fetchAllOfficialOffices(): Promise<OfficesResponse> {
    try {
      const { data } = await new RunwayControllerApi(
        getBFFConfiguration()
      ).getAllOfficialOffices();
      return data;
    } catch (e) {
      ErrorService.notify('Unable to fetch all official offices', e);
    }
  }

  async createAgentLead(
    createAgentLeadRequest: CreateAgentLeadRequest
  ): Promise<AgentLeadResponse> {
    try {
      const { data } = await new RunwayControllerApi(
        getBFFConfiguration()
      ).createAgentLead(createAgentLeadRequest);
      return data;
    } catch (e) {
      ErrorService.notify('Unable to create agent lead', e, {
        agent: { id: createAgentLeadRequest.agentId },
        createLeadRequest: { ...createAgentLeadRequest },
      });
    }
  }
}
